import React from "react"
import Layout from "../layouts"
import SEO from "../components/seo"
import Heading from "../components/Heading"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Heading>NOT FOUND</Heading>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
